@tailwind base;
@tailwind components;
@tailwind utilities;

div[class^="swiper-pagination"] {
    @apply !left-0 !transform-none;
}

span[class^="swiper-pagination-bullet"] {
    @apply bg-white;
}